import React, { useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { useUpdatePriorityStatusMutation } from '../../../../features/users/usersApiSlice';
import useAuth from '../../../../hooks/useAuth';

const BidListModal = ({ pnms, onClose }) => {
    const { chapter, roles } = useAuth();
    const [updatePriorityStatus] = useUpdatePriorityStatusMutation();
    const [favoritedPnms, setFavoritedPnms] = useState(
        pnms.filter(pnm => {
            const chapterInfo = pnm.pnmInfo.chaptersFollowing.find(chap => chap.chapter.toString() === chapter.toString());
            return chapterInfo?.priority === 3;
        })
    );

    // Helper function to format phone numbers
    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove all non-numeric characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Capture the phone number parts
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`; // Format the number
        }
        return phoneNumber; // If phone number doesn't match, return as is
    };

    const handleRemoveFromBidList = async (pnmId) => {
        try {
            await updatePriorityStatus({
                userId: pnmId,
                chapterId: chapter,
                priority: 1, // Setting priority to 1 removes the PNM from the bid list
            }).unwrap();
            // Update the local state to reflect the removal
            setFavoritedPnms(prevFavoritedPnms => prevFavoritedPnms.filter(pnm => pnm._id !== pnmId));
        } catch (error) {
            console.error('Error updating priority status:', error);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[80vh] overflow-y-auto">
                <div className="sticky top-0 bg-white z-10 p-4 border-b flex justify-between items-center">
                    <h2 className="text-xl font-bold">Bid List</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <IoIosClose size={28} />
                    </button>
                </div>
                <ul className="divide-y divide-gray-200 px-4 pb-4">
                    {favoritedPnms.length === 0 ? (
                        <div>No favorites found</div>
                    ) : (
                        favoritedPnms.map(pnm => (
                            <li key={pnm._id} className="py-2 flex justify-between items-center">
                                <div>
                                    <span className="block font-bold">{pnm.firstName} {pnm.lastName}</span>
                                    {pnm.phoneNumber && (
                                        <span className="text-gray-600">
                                            {formatPhoneNumber(pnm.phoneNumber)}
                                        </span>
                                    )}
                                </div>
                                {roles.includes('Officer') && (
                                    <button
                                        onClick={() => handleRemoveFromBidList(pnm._id)}
                                        className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                                    >
                                        Remove
                                    </button>
                                )}
                            </li>
                        ))
                    )}
                </ul>
            </div>
        </div>
    );
};

export default BidListModal;