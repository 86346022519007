import React from 'react';
import useAuth from '../../hooks/useAuth';
import Active from '../pages/active/dashboard/Active';
import UnverifiedActive from '../pages/active/UnverifiedActive';
import Pnm from '../pages/pnm/Pnm';
import Public from '../pages/public/Public';
import Loading from '../pages/public/Loading';

const Home = () => {
    const { roles, verified } = useAuth();

    if (roles.includes('Active') && verified === true) {
        return <Active />
    } else if (roles.includes('Active') && verified === false) {
        return <UnverifiedActive />
    } else if (roles.includes('Active') && verified === undefined) {
        return <Loading />
    } else if (roles.includes('PNM')) {
        return <Pnm />
    } else {
        return <Public />
    }
};

export default Home;