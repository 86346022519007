import  { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
    name: 'users',
    initialState: { members: [] },
    reducers: {
        setMembers: (state, action) => {
            const { members } = action.payload;
            state.members = members;
        },
        clearMembers: (state) => {
            state.members = []
        }
    }
});

export const { setMembers, clearMembers } = usersSlice.actions;

export default usersSlice.reducer;

export const selectCurrentMembers = (state) => state.users.members;