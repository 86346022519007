import { Routes, Route, Navigate } from 'react-router-dom';
import PersistLogin from './features/auth/PersistLogin';
import HomeLayout from './components/layout/HomeLayout';
import EventsLayout from './components/layout/EventsLayout';
import ChapterLayout from './components/layout/ChapterLayout';
import RecruitmentLayout from './components/layout/RecruitmentLayout';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<PersistLogin />}>
        <Route index element={<HomeLayout />} />
        <Route path="events" element={<EventsLayout />} />
        <Route path="chapter" element={<ChapterLayout />} />
        <Route path="recruitment" element={<RecruitmentLayout />} />
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;