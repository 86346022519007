import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import ActiveEvents from '../pages/active/events/ActiveEvents';
import Pnm from '../pages/pnm/Pnm';

const EventsLayout = () => {
    const { roles } = useAuth();

    if (roles.includes('Active')) {
        return <ActiveEvents />
    } else if (roles.includes('PNM')) {
        return <Pnm />
    } else {
        return <Navigate to="/" replace />
    }
};

export default EventsLayout;