import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useRefreshMutation } from './authApiSlice';
import { Navigate, Outlet } from 'react-router-dom';
import Loading from '../../components/pages/public/Loading';
import { useGetUserEventsQuery } from '../events/eventsApiSlice';
import { useGetChapterUsersQuery } from '../users/usersApiSlice';

const PersistLogin = () => {
    const persist = JSON.parse(localStorage.getItem('persist')) || false;
    const token = useSelector(state => state.auth.token);
    const user = useSelector(state => state.auth.user);
    const effectRan = useRef(false);
    const [trueSuccess, setTrueSuccess] = useState(false);

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
    }] = useRefreshMutation();

    const { isEventsLoading, isEventsError } = useGetUserEventsQuery(undefined, { skip: !token });
    const { isMembersLoading, isMembersError } = useGetChapterUsersQuery({ chapterId: user?.chapter }, { skip: !user || !user.chapter} );

    useEffect(() => {
        if (effectRan.current === true || process.env.NODE_ENV !== 'development') {
            const verifyRefreshToken = async () => {
                try {
                    await refresh();
                    setTrueSuccess(true);
                } catch (err) {
                    console.log(err);
                }
            };
            if (!token && persist) verifyRefreshToken();
        }
        return () => effectRan.current = true;
    }, [persist, refresh, token]);

    let content;
    if (!persist) {
        content = <Outlet />;
    } else if (isLoading || isEventsLoading || isMembersLoading) {
        content = <Loading />;
    } else if (isError || isEventsError || isMembersError) {
        content = <Navigate to="/" />;
    } else if (isSuccess && trueSuccess) {
        content = <Outlet />;
    } else if (token && isUninitialized) {
        content = <Outlet />;
    }

    return content;
};

export default PersistLogin;