import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';

const useAuth = () => {
    const token = useSelector(state => state.auth.token);
    const user = useSelector(state => state.auth.user);

    let id = '';
    let roles = [];
    let verified;
    let chapter;
    let organization;
    let pnmInfo;

    if (token) {
        const decoded = jwtDecode(token);
        id = decoded.UserInfo.id;
        roles = decoded.UserInfo.roles;
    }

    if (user) {
        verified = user.verified;
        chapter = user.chapter
        organization = user.organization

        if (roles.includes('PNM')) {
            pnmInfo = user.pnmInfo;
        }
    }

    return { id, roles, verified, chapter, organization, pnmInfo };
};

export default useAuth;