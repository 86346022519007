import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const organizationsAdapter = createEntityAdapter({});

const initialState = organizationsAdapter.getInitialState();

export const organizationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrganizations: builder.query({
            query: () => '/organizations',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedOrganizations = responseData.map(organization => {
                    organization.id = organization._id;
                    return organization;
                });
                return organizationsAdapter.setAll(initialState, loadedOrganizations);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Organization', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Organization', id }))
                    ]
                } else return [{ type: 'Organization', id: 'LIST' }];
            }
        }),
        getAllOrganizationNames: builder.query({
            query: () => '/organizations/names',
            providesTags: [{ type: 'Organization', id: 'LIST' }]
        }),
        getOrganization: builder.query({
            query: (id) => `/organizations/${id}`,
            providesTags: (result, error, id) => [{ type: 'Organization', id }]
        }),
        getOrganizationUsers: builder.query({
            query: (organizationId) => `/organizations/${organizationId}/users`,
            providesTags: (result, error, id) => [{ type: 'OrganizationUsers', id }]
        }),
        getOrganizationPnms: builder.query({
            query: (organizationId) => `/organizations/${organizationId}/pnms`,
            providesTags: (result, error, id) => [{ type: 'OrganizationPnms', id }]
        }),
        getOrganizationPnmNames: builder.query({
            query: (organizationId) => `/organizations/${organizationId}/pnms/names`,
            providesTags: (result, error, id) => [{ type: 'OrganizationPnmNames', id }]
        }),
        getOrganizationChapters: builder.query({
            query: (organizationId) => `/organizations/${organizationId}/chapters`,
            providesTags: (result, error, id) => [{ type: 'OrganizationChapters', id }]
        }),
        getOrganizationChapterNames: builder.query({
            query: (organizationId) => `/organizations/${organizationId}/chapters/names`,
            providesTags: (result, error, id) => [{ type: 'OrganizationChapterNames', id }]
        }),
        addNewOrganization: builder.mutation({
            query: initialOrganizationData => ({
                url: '/organizations',
                method: 'POST',
                body: {
                    ...initialOrganizationData,
                }
            }),
            invalidatesTags: [
                { type: 'Organization', id: 'LIST' }
            ]
        }),
        updateOrganization: builder.mutation({
            query: initialOrganizationData => ({
                url: '/organizations',
                method: 'PATCH',
                body: {
                    ...initialOrganizationData,
                }
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'Organization', id }
            ]
        }),
        deleteOrganization: builder.mutation({
            query: ({ id }) => ({
                url: '/organizations',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'Organization', id }
            ]
        }),
    }),
});

export const {
    useGetOrganizationsQuery,
    useGetOrganizationByIdQuery,
    useGetAllOrganizationNamesQuery,
    useGetOrganizationUsersQuery,
    useGetOrganizationPnmsQuery,
    useGetOrganizationPnmNamesQuery,
    useGetOrganizationChaptersQuery,
    useGetOrganizationChapterNamesQuery,
    useAddNewOrganizationMutation,
    useUpdateOrganizationMutation,
    useDeleteOrganizationMutation
} = organizationsApiSlice;

export const selectOrganizationsResult = organizationsApiSlice.endpoints.getOrganizations.select();

const selectOrganizationsData = createSelector(
    selectOrganizationsResult,
    organizationsResult => organizationsResult.data
);

export const {
    selectAll: selectAllOrganizations,
    selectById: selectOrganizationById,
    selectIds: selectOrganizationIds
} = organizationsAdapter.getSelectors(state => selectOrganizationsData(state) ?? initialState);