import React, { useState, useEffect, useRef } from 'react';
import { IoIosClose } from 'react-icons/io';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { selectAllOrganizations } from '../../../features/organizations/organizationsApiSlice';
import { selectAllChapters } from '../../../features/chapters/chaptersApiSlice';
import { useNavigate } from 'react-router-dom';
import { useAddNewUserMutation } from '../../../features/users/usersApiSlice';
import { useLoginMutation } from '../../../features/auth/authApiSlice';

const SignUpModal = ({ isOpen, onClose }) => {
    const [step, setStep] = useState(1);
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        roles: [''],
        studentId: '',
        organization: '',
        password: '',
        image: null,
        agreedToTerms: false,
        homeTownCity: '',
        homeTownState: '',
        homeCountry: 'United States',
        customCountry: '',
        gradeLevel: '',
        major: '',
        secondMajor: '',
        minor: '',
        gpa: '',
        instagram: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        chapter: '',
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [canCheck, setCanCheck] = useState(false);

    const organizations = useSelector(selectAllOrganizations);
    const chapters = useSelector(selectAllChapters);

    const navigate = useNavigate();
    const [addNewUser, { isLoading }] = useAddNewUserMutation();
    const [login, { isLoginLoading }] = useLoginMutation();
    const termsRef = useRef(null);

    useEffect(() => {
        const { firstName, lastName, email, phoneNumber, roles, studentId, organization, password, chapter, image } = formData;
        const basicFieldsFilled = firstName && lastName && email && phoneNumber && roles && studentId && password && organization && image;
        const isFormComplete = roles.includes('Active') ? basicFieldsFilled && chapter : basicFieldsFilled;

        setIsFormValid(isFormComplete);
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        let updatedValue = value;

        if (['firstName', 'lastName', 'homeTownCity', 'homeTownState', 'homeCountry', 'major', 'secondMajor', 'minor'].includes(name)) {
            updatedValue = updatedValue.charAt(0).toUpperCase() + updatedValue.slice(1);
        }

        setFormData({
            ...formData,
            [name]: updatedValue,
        });
    };

    const handleCountryChange = (e) => {
        const { value } = e.target;
        if (value === 'Other') {
            setFormData({
                ...formData,
                homeCountry: '',
                customCountry: '',
            });
        } else {
            setFormData({
                ...formData,
                homeCountry: value,
                customCountry: '',
            });
        }
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: [value]
        });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                image: file,
            });
        }
    };

    const handleTakePicture = () => {
        document.getElementById('imageInput').click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isFormValid && formData.agreedToTerms) {
            try {
                const formDataToSend = new FormData();
                for (let key in formData) {
                    if (key === 'image' && formData[key]) {
                        formDataToSend.append('image', formData[key]);
                    } else if (key === 'roles') {
                        formDataToSend.append('roles[]', formData[key]);
                    } else if (key === 'homeCountry' && formData.customCountry) {
                        formDataToSend.append('homeCountry', formData.customCountry);
                    } else {
                        formDataToSend.append(key, formData[key]);
                    }
                }
                const signUpResponse = await addNewUser(formDataToSend).unwrap();
                console.log('Sign-up success:', signUpResponse);

                const { email, password } = formData;

                await login({ email, password }).unwrap();
                localStorage.setItem('persist', true);
                navigate('/');
                onClose();
            } catch (err) {
                console.error('Sign-up or login error:', err);
                setErrMsg(err.data?.message || 'Sign-up or login failed');
            }
        } else {
            setErrMsg('Please fill out all required fields and agree to the terms.');
        }
    };

    const handleScroll = () => {
        if (termsRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setCanCheck(true);
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const filteredChapters = chapters.filter(chapter => chapter.organization === formData.organization).sort((a, b) => a.name.localeCompare(b.name));

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
            <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-full overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">Sign Up</h2>
                    <button
                        onClick={() => {
                            onClose();
                            setStep(1);
                            setFormData({ ...formData, agreedToTerms: false });
                            setCanCheck(false);
                        }}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <IoIosClose size={32} />
                    </button>
                </div>
                {errMsg && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                        <strong className="font-bold">Error: </strong>
                        <span>{errMsg}</span>
                    </div>
                )}
                {step === 1 && (
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        if (formData.roles.includes('Active')) {
                            setStep(4);
                        } else if (formData.roles.includes('PNM')) {
                            setStep(2);
                        }}} className="flex flex-col gap-4">
                        <div className="flex gap-2">
                            <input
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                className="flex-1 border border-gray-300 text-gray-900 rounded-lg p-3"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                className="flex-1 border border-gray-300 text-gray-900 rounded-lg pl-3"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <input
                            type="number"
                            name="studentId"
                            placeholder="ASUID Number"
                            min="1000000000"
                            max="9999999999"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.studentId !== null ? formData.studentId : ''}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <div className="relative flex items-center">
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                className="border border-gray-300 text-gray-900 rounded-lg p-3 flex-1"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                            <button
                                type="button"
                                className="absolute right-3"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                        <select
                            name="roles"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.roles[0]}
                            onChange={handleSelectChange}
                            required
                        >
                            <option value="">Select Status</option>
                            <option value="PNM">PNM</option>
                            <option value="Active">Active</option>
                        </select>
                        <select
                            name="organization"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.organization}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Organization</option>
                            {organizations.map(org => (
                                <option key={org.id} value={org.id}>{org.name}</option>
                            ))}
                        </select>
                        {formData.roles.includes('Active') && formData.organization && (
                            <select
                                name="chapter"
                                className="border border-gray-300 text-gray-900 rounded-lg p-3"
                                value={formData.chapter}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Chapter</option>
                                {filteredChapters.map(chapter => (
                                    <option key={chapter.id} value={chapter.id}>{chapter.name}</option>
                                ))}
                            </select>
                        )}
                        <div className="flex flex-col items-center gap-4">
                            {formData.image ? (
                                <img
                                    src={URL.createObjectURL(formData.image)}
                                    alt="Selected"
                                    className="w-32 h-32 object-cover rounded-full"
                                />
                            ) : (
                                <div className="w-32 h-32 bg-gray-200 rounded-full flex items-center justify-center">
                                    <span>No image</span>
                                </div>
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                id="imageInput"
                                className="hidden"
                                onChange={handleImageChange}
                            />
                            <button
                                type="button"
                                onClick={handleTakePicture}
                                className="bg-pacific-blue hover:bg-turquoise-blue text-white font-bold py-2 px-4 rounded"
                            >
                                Add/Take Image
                            </button>
                        </div>
                        <button
                            type="submit"
                            className={`bg-pacific-blue hover:bg-turquoise-blue text-white font-bold py-2 px-4 rounded ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={!isFormValid}
                        >
                            Next
                        </button>
                    </form>
                )}
                {step === 2 && formData.roles.includes('PNM') && (
                    <form onSubmit={(e) => { e.preventDefault(); setStep(3); }} className="flex flex-col gap-4">
                        <h3 className="text-xl font-bold">Complete Your Profile</h3>
                        {formData.homeCountry === 'United States' && (
                            <div className="flex gap-2">
                                <input
                                    type="text"
                                    name="homeTownCity"
                                    placeholder="Home Town City"
                                    className="flex-1 border border-gray-300 text-gray-900 rounded-lg p-3"
                                    value={formData.homeTownCity}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="homeTownState"
                                    placeholder="Home Town State"
                                    className="flex-1 border border-gray-300 text-gray-900 rounded-lg pl-3"
                                    value={formData.homeTownState}
                                    onChange={handleChange}
                                />
                            </div>
                        )}
                        <select
                            name="homeCountry"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.homeCountry || 'Other'}
                            onChange={handleCountryChange}
                            required
                        >
                            <option value="United States">United States</option>
                            <option value="Other">Other</option>
                        </select>
                        {formData.homeCountry === '' && (
                            <input
                                type="text"
                                name="customCountry"
                                placeholder="Enter Your Country"
                                className="border border-gray-300 text-gray-900 rounded-lg p-3"
                                value={formData.customCountry}
                                onChange={handleChange}
                                required
                            />
                        )}
                        <select
                            name="gradeLevel"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.gradeLevel}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Grade Level</option>
                            <option value="Freshman">Freshman</option>
                            <option value="Sophomore">Sophomore</option>
                            <option value="Junior">Junior</option>
                            <option value="Senior">Senior</option>
                        </select>
                        <input
                            type="text"
                            name="major"
                            placeholder="Major"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.major}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="secondMajor"
                            placeholder="Second Major (Optional)"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.secondMajor}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name="minor"
                            placeholder="Minor (Optional)"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.minor}
                            onChange={handleChange}
                        />
                        <input
                            type="number"
                            name="gpa"
                            placeholder="GPA"
                            step="0.01"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.gpa}
                            onChange={handleChange}
                            required
                        />
                        <button
                            type="submit"
                            className="bg-pacific-blue hover:bg-turquoise-blue text-white font-bold py-3 px-4 rounded"
                        >
                            Next
                        </button>
                    </form>
                )}
                {step === 3 && formData.roles.includes('PNM') && (
                    <form onSubmit={(e) => { e.preventDefault(); setStep(4); }} className="flex flex-col gap-4">
                        <h3 className="text-xl font-bold">Social Media Handles (Optional)</h3>
                        <input
                            type="text"
                            name="instagram"
                            placeholder="Instagram Handle"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.instagram}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name="facebook"
                            placeholder="Facebook Handle"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.facebook}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name="twitter"
                            placeholder="Twitter Handle"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.twitter}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name="linkedin"
                            placeholder="LinkedIn Handle"
                            className="border border-gray-300 text-gray-900 rounded-lg p-3"
                            value={formData.linkedin}
                            onChange={handleChange}
                        />
                        <button
                            type="submit"
                            className="bg-pacific-blue hover:bg-turquoise-blue text-white font-bold py-3 px-4 rounded"
                        >
                            Next
                        </button>
                    </form>
                )}
                {step === 4 && (
                    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                        <h3 className="text-xl font-bold">Terms of Service</h3>
                        <div
                            ref={termsRef}
                            onScroll={handleScroll}
                            className="bg-gray-100 p-4 rounded-lg overflow-y-auto max-h-48 text-sm"
                        >
                            <p>
                                <strong>Grade, ASU Registration, Credit Hour, and Conduct Record Release</strong>
                                <br />
                                This document constitutes Consent to obtain and release my grades, ASU registration and credit hour information. 
                                It is understood that by signing below, I agree that ASU through the Fraternity & Sorority Life (FSL) staff may 
                                obtain my grades, ASU Registration, credit hour, and conduct information directly from the university and in turn 
                                release this information in accordance with this Consent. I further agree that ASU through FSL may discuss this 
                                information with the Authorized Recipients. This Consent applies to educational records that may otherwise be protected 
                                under the Family Educational Rights and Privacy Act of 1974, as amended, 20 U.S.C. 1232g. I specifically authorize and 
                                Consent to the release of this information to the President of my fraternity or sorority, council, and/or inter/national 
                                organization for the purpose of determining my membership status and overall chapter academic success. I acknowledge that 
                                this Consent is valid unless I revoke it in writing and present it to Fraternity & Sorority Life. Finally, by signing this 
                                document, I attest that I am enrolled in at least one credit hour at Arizona State University. I am also acknowledging 
                                my understanding that if at any time, I choose not to release grades or conduct record as stated above, I must submit 
                                my written request to the Office of Fraternity & Sorority Life.
                            </p>
                            <p>
                                <strong>Standard Photo Release</strong>
                                <br />
                                Additionally, by signing this form I also grant permission to FSL, on behalf of ASU, to use photographs taken of me 
                                during ASU events for use in university publications, websites or other electronic forms or media, and to offer the 
                                photographs for use or distribution to other university departments, without notifying me. I waive any right to inspect 
                                or approve the photographs, publications, or electronic matter that may be used in conjunction with them now or in the 
                                future, whether that use is known to me or unknown and I waive any rights to royalties or other compensation arising from 
                                or related to the use of the photographs. Also, I agree to release and hold harmless the Arizona Board of Regents, on behalf 
                                of Arizona State University, from and against any claims, damages or liability arising from or related to the use of these 
                                photographs, including but not limited to any re-use, distortion, blurring, alteration, optical illusion or use in composite 
                                form, either intentionally or otherwise, that may occur or be produced in production of the finished product.
                            </p>
                            <p>
                                <strong>Acknowledgment of University Policies</strong>
                                <br />
                                Further, by signing this form I acknowledge the ABOR Student Code of Conduct and any other policy pertaining to individual students, 
                                registered student organizations, my chapter, and my council. This includes, but is not limited to SSM 1301-01: Fraternities and 
                                Sororities-Relationship with the University.
                            </p>
                        </div>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                name="agreedToTerms"
                                className="mr-2"
                                checked={formData.agreedToTerms}
                                onChange={() => setFormData({ ...formData, agreedToTerms: !formData.agreedToTerms })}
                                disabled={!canCheck}
                                required
                            />
                            I agree to the terms and conditions
                        </label>
                        <button
                            type="submit"
                            className={`bg-pacific-blue hover:bg-turquoise-blue text-white font-bold py-2 px-4 rounded ${(!formData.agreedToTerms || isLoading || isLoginLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={!formData.agreedToTerms || isLoading || isLoginLoading}
                        >
                            Sign Up
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default SignUpModal;