import React, { useState } from 'react';
import { useRsvpToEventMutation } from '../../../features/events/eventsApiSlice';
import useAuth from '../../../hooks/useAuth';

const PnmEventCard = ({ event }) => {
    const { id: userId } = useAuth();
    const [rsvpToEvent, { isLoading }] = useRsvpToEventMutation();
    const [rsvpStatus, setRsvpStatus] = useState(event.attendees.some(attendee => attendee.user.toString() === userId));

    const handleRsvp = async () => {
        try {
            await rsvpToEvent({ eventId: event._id, userId }).unwrap();
            setRsvpStatus(true);
        } catch (err) {
            if (err?.data?.message === "User already RSVP'd") {
                setRsvpStatus(true);
            } else {
                console.error('Failed to RSVP:', err);
                alert('Failed to RSVP. Please try again later.');
            }
        }
    };

    const mapLink = getMapsLink(event.location?.address || '');

    const formattedDate = formatEventDate(event.start, event.end);

    const isRelativeDate = formattedDate.startsWith('Today') || formattedDate.startsWith('Tomorrow');
    const datePart = isRelativeDate ? formattedDate.split(' ')[0] : formattedDate.split(' ').slice(0, 3).join(' ');
    const timePart = isRelativeDate ? formattedDate.split(' ').slice(1).join(' ') : formattedDate.split(' ').slice(3).join(' ');

    return (
        <div className="bg-event-card-back shadow-md rounded-lg py-4 flex justify-evenly items-center">
            <div className="flex flex-col bg-event-card-left rounded-lg p-4 items-center w-1/2">
                <p className="text-lg font-bold mb-1">{event.chapterName}</p>
                <p className="text-gray-600 mb-1">{event.location?.name || 'No location specified'}</p>
                {event.location?.address && (
                    <a href={mapLink} target="_blank" rel="noopener noreferrer" className="text-gray-600 underline text-center">
                        {event.location.address}
                    </a>
                )}
            </div>
            <div className="flex flex-col items-center">
                <p className="text-lg font-bold">{datePart}</p>
                <p className="text-gray-600 mb-2">{timePart}</p>
                <button
                    onClick={handleRsvp}
                    className={`${
                        rsvpStatus ? 'bg-gray-500 cursor-not-allowed drop-shadow-lg' : 'bg-event-card-button drop-shadow-2xl hover:bg-event-card-button-hover'
                    } text-white py-2 px-4 rounded shadow`}
                    disabled={rsvpStatus || isLoading}
                >
                    {rsvpStatus ? 'Going' : 'RSVP'}
                </button>
            </div>
        </div>
    );
};

function getMapsLink(address) {
    const encodedAddress = encodeURIComponent(address);

    if (/iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        return `https://maps.apple.com/?q=${encodedAddress}`;
    } else {
        return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    }
}

export function formatEventDate(start, end) {
    const eventStart = new Date(start);
    const eventEnd = new Date(end);

    const now = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(now.getDate() + 1);

    const formatTime = (date) => {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return minutes === 0 ? `${hours} ${ampm}` : `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    };

    if (eventStart.toDateString() === now.toDateString()) {
        return `Today ${formatTime(eventStart)} - ${formatTime(eventEnd)}`;
    } else if (eventStart.toDateString() === tomorrow.toDateString()) {
        return `Tomorrow ${formatTime(eventStart)} - ${formatTime(eventEnd)}`;
    } else {
        const formattedDate = eventStart.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
        return `${formattedDate} ${formatTime(eventStart)} - ${formatTime(eventEnd)}`;
    }
}

export default PnmEventCard;