import React, { useState } from 'react';
import useAuth from '../../../../hooks/useAuth';

const PnmTable = ({ pnms = [], onSelectPnm }) => {
    const { chapter } = useAuth();
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    const getPriorityForChapter = (pnm, chapterId) => {
        if (!pnm.pnmInfo || !pnm.pnmInfo.chaptersFollowing) {
            return 0;
        }

        const chapterFollowing = pnm.pnmInfo.chaptersFollowing.find(chap => {
            return chap.chapter.toString() === chapterId;
        });

        if (chapterFollowing) {
            return chapterFollowing.priority || 0;
        } else {
            return 0;
        }
    };

    const sortedPnms = React.useMemo(() => {
        if (sortConfig.key !== null) {
            return [...pnms].sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Special handling for sorting priority
                if (sortConfig.key === 'priority') {
                    const aPriority = getPriorityForChapter(a, chapter.toString());
                    const bPriority = getPriorityForChapter(b, chapter.toString());

                    if (sortConfig.direction === 'ascending') {
                        return bPriority - aPriority;
                    } else {
                        return aPriority - bPriority;
                    }
                }

                // Default sorting for non-priority columns
                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return pnms;
    }, [pnms, sortConfig, chapter]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getClassNamesFor = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? 'sort-asc' : 'sort-desc';
        }
        return '';
    };

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumberString;
    };

    const getPriorityLabel = (priority) => {
        switch (priority) {
            case 3:
                return 'Yes';
            case 2:
                return 'Maybe';
            case 1:
                return 'No';
            default:
                return '';
        }
    };

    if (sortedPnms.length === 0) {
        return (
            <div className="text-center text-gray-700">
                No PNMs found.
            </div>
        );
    }

    return (
        <div className="overflow-auto h-full w-full">
            <table className="min-w-full bg-white shadow-md rounded-lg mb-4">
                <thead className="sticky top-0 bg-gray-100 z-10">
                    <tr>
                        <th
                            className={`py-2 px-4 border-b border-r cursor-pointer ${getClassNamesFor('studentId')}`}
                            onClick={() => requestSort('studentId')}
                        >
                            Student ID
                        </th>
                        <th
                            className={`py-2 px-4 border-b border-r cursor-pointer ${getClassNamesFor('firstName')}`}
                            onClick={() => requestSort('firstName')}
                        >
                            First Name
                        </th>
                        <th
                            className={`py-2 px-4 border-b border-r cursor-pointer ${getClassNamesFor('lastName')}`}
                            onClick={() => requestSort('lastName')}
                        >
                            Last Name
                        </th>
                        <th
                            className={`py-2 px-4 border-b border-r cursor-pointer ${getClassNamesFor('email')}`}
                            onClick={() => requestSort('email')}
                        >
                            Email
                        </th>
                        <th
                            className={`py-2 px-4 border-b border-r cursor-pointer ${getClassNamesFor('phoneNumber')}`}
                            onClick={() => requestSort('phoneNumber')}
                        >
                            Phone Number
                        </th>
                        <th
                            className={`py-2 px-4 border-b cursor-pointer ${getClassNamesFor('priority')}`}
                            onClick={() => requestSort('priority')}
                        >
                            Vote
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedPnms.map((pnm) => {
                        const priority = getPriorityForChapter(pnm, chapter.toString());
                        const priorityLabel = getPriorityLabel(priority);

                        return (
                            <tr
                                key={pnm._id}
                                className="hover:bg-gray-50 cursor-pointer"
                                onClick={() => onSelectPnm(pnm, sortedPnms)}
                            >
                                <td className="py-2 px-4 border-b border-r">{pnm.studentId}</td>
                                <td className="py-2 px-4 border-b border-r">{pnm.firstName}</td>
                                <td className="py-2 px-4 border-b border-r">{pnm.lastName}</td>
                                <td className="py-2 px-4 border-b border-r">{pnm.email}</td>
                                <td className="py-2 px-4 border-b border-r">{formatPhoneNumber(pnm.phoneNumber)}</td>
                                <td className="py-2 px-4 border-b">{priorityLabel}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default PnmTable;