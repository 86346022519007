import React from 'react';
import { useGetOrganizationChapterNamesQuery } from '../../../features/organizations/organizationsApiSlice';
import { useFollowChapterMutation } from '../../../features/users/usersApiSlice';
import { AiOutlineClose } from 'react-icons/ai';

const ChapterSearchModal = ({ organizationId, userId, followedChapters, isOpen, onClose, onFollowChange }) => {
    const { data: chapters = [], isLoading, isError } = useGetOrganizationChapterNamesQuery(organizationId);
    const [followChapter] = useFollowChapterMutation();

    const handleFollow = async (chapterId, isFollowing) => {
        try {
            await followChapter({ userId, chapterId, follow: !isFollowing }).unwrap();
            const updatedFollowedChapters = isFollowing
                ? followedChapters.filter(id => id !== chapterId.toString())
                : [...followedChapters, chapterId.toString()];
            onFollowChange(updatedFollowedChapters);
        } catch (err) {
            console.error('Failed to update chapter following:', err);
        }
    };

    if (!isOpen) return null;

    const sortedChapters = [...chapters].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg p-6 w-10/12 max-w-lg">
                <button onClick={onClose} className="absolute top-3 right-3 text-gray-500 hover:text-gray-700">
                    <AiOutlineClose size={24} />
                </button>
                <h2 className="text-xl font-bold mb-4 text-center">Follow Chapters</h2>

                {isLoading && <div>Loading chapters...</div>}
                {isError && <div>Error loading chapters.</div>}

                {!isLoading && !isError && sortedChapters.length === 0 && (
                    <div className="text-center text-gray-700">No chapters found</div>
                )}

                {!isLoading && !isError && sortedChapters.length > 0 && (
                    <ul className="divide-y divide-gray-200">
                        {sortedChapters.map(chapter => {
                            const isFollowing = followedChapters.includes(chapter._id.toString());
                            return (
                                <li key={chapter._id} className="py-2 flex justify-between items-center">
                                    <span>{chapter.name}</span>
                                    {isFollowing 
                                        ? (
                                            <button
                                                onClick={() => handleFollow(chapter._id, isFollowing)}
                                                className="cursor-pointer py-1 px-4 rounded bg-gray-500 text-white hover:opacity-90"
                                            >
                                                Unfollow
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() => handleFollow(chapter._id, isFollowing)}
                                                className="cursor-pointer py-1 px-4 rounded bg-green-500 text-white hover:opacity-90"
                                            >
                                                Follow
                                            </button>
                                        )
                                        }
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ChapterSearchModal;