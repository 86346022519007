import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const eventsAdapter = createEntityAdapter({
    sortComparer: (a, b) => new Date(a.start) - new Date(b.start)
});

const eventAttendeesAdapter = createEntityAdapter({});

const initialEventsState = eventsAdapter.getInitialState();

const initialEventAttendeesState = eventAttendeesAdapter.getInitialState();

export const eventsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserEvents: builder.query({
            query: () => '/users/events',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: (response) => {
                const loadedEvents = response?.map(event => {
                    event.id = event._id;
                    return event;
                });
                return eventsAdapter.setAll(initialEventsState, loadedEvents);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Event', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Event', id }))
                    ]
                } else return [{ type: 'Event', id: 'LIST' }]
            }
        }),
        getEventAttendees: builder.query({
            query: (eventId) => `/events/${eventId}/attendees`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: (response) => {
                const loadedAttendees = response?.map(attendee => {
                    attendee.id = attendee._id;
                    return attendee;
                });
                return eventAttendeesAdapter.setAll(initialEventAttendeesState, loadedAttendees);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'EventAttendees', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'EventAttendees', id }))
                    ]
                } else return [{ type: 'EventAttendees', id: 'LIST' }]
            }
        }),
        addNewEvent: builder.mutation({
            query: (newEvent) => ({
                url: '/events',
                method: 'POST',
                body: newEvent
            }),
            invalidatesTags: [{ type: 'Event', id: 'LIST' }]
        }),
        updateEvent: builder.mutation({
            query: (updatedEvent) => ({
                url: '/events',
                method: 'PATCH',
                body: updatedEvent
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.id }
            ]
        }),
        rsvpToEvent: builder.mutation({
            query: ({ eventId, userId }) => ({
                url: `/events/${eventId}/rsvp`,
                method: 'PATCH',
                body: { userId },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.eventId },
            ],
        }),
        verifyAttendance: builder.mutation({
            query: ({ eventId, userId }) => ({
                url: `/events/${eventId}/verify`,
                method: 'PATCH',
                body: { userId },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.eventId },
            ],
        }),
        deleteEvent: builder.mutation({
            query: ({ id }) => ({
                url: '/events',
                method: 'DELETE',
                body: { id },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.id }
            ]
        })
    }),
});

export const {
    useGetUserEventsQuery,
    useGetEventAttendeesQuery,
    useAddNewEventMutation,
    useUpdateEventMutation,
    useRsvpToEventMutation,
    useVerifyAttendanceMutation,
    useDeleteEventMutation
} = eventsApiSlice;

export const selectEventsResult = eventsApiSlice.endpoints.getUserEvents.select();
export const selectEventAttendeesResult = eventsApiSlice.endpoints.getEventAttendees.select();

const selectEventsData = createSelector(
    selectEventsResult,
    eventsResult => eventsResult.data
);

const selectEventAttendeesData = createSelector(
    selectEventAttendeesResult,
    eventAttendeesResult => eventAttendeesResult.data
);

export const {
    selectAll: selectAllEvents,
    selectById: selectEventById,
    selectIds: selectEventIds,
} = eventsAdapter.getSelectors(state => selectEventsData(state) ?? initialEventsState);

export const {
    selectAll: selectAllEventAttendees,
    selectById: selectEventAttendeeById,
    selectIds: selectEventAttendeeIds
} = eventAttendeesAdapter.getSelectors(state => selectEventAttendeesData(state) ?? initialEventAttendeesState);