import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineLeft, AiOutlineRight, AiOutlineClose } from 'react-icons/ai';
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { useUpdatePriorityStatusMutation } from '../../../../features/users/usersApiSlice';
import Loading from '../../public/Loading';

const PnmDetailsModal = ({ pnm, chapterId, recruitmentEvents, onClose, onNext, onPrev, isOfficer, onPriorityChange }) => {
    const [priority, setPriority] = useState(0);
    const [loading, setLoading] = useState(true);
    const [updatePriorityStatus] = useUpdatePriorityStatusMutation();

    const getPriority = useCallback(() => {
        const chapterInfo = pnm.pnmInfo.chaptersFollowing.find(chapter => chapter.chapter.toString() === chapterId);
        return chapterInfo ? chapterInfo.priority : 0;
    }, [pnm, chapterId]);

    useEffect(() => {
        setPriority(getPriority());
    }, [getPriority]);

    const handlePriorityChange = async (newPriority) => {
        if (isOfficer) {
            try {
                await updatePriorityStatus({
                    userId: pnm._id,
                    chapterId: chapterId,
                    priority: newPriority,
                }).unwrap();
                setPriority(newPriority);
                onPriorityChange(pnm._id, newPriority);
            } catch (error) {
                console.error('Error updating priority status:', error);
            }
        }
    };

    const handleImageLoad = () => {
        setLoading(false);
    };

    const handleImageError = () => {
        setLoading(false);
    };

    const checkAttendance = (event) => {
        return event.attendees.some(att => att.user === pnm._id);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <button onClick={onPrev} className="text-white">
                <AiOutlineLeft size={32} />
            </button>
            <div className="relative rounded-lg shadow-lg w-full max-w-md flex flex-col items-center">
                <div className="relative w-full h-40 overflow-hidden rounded-t-lg">
                    {loading && (
                        <div className="absolute inset-0 flex items-center justify-center z-10 bg-gray-100">
                            <Loading />
                        </div>
                    )}
                    <img
                        src={pnm.profilePicture}
                        alt="Background"
                        className="absolute inset-0 w-full h-full object-cover filter blur-lg scale-125"
                        onLoad={handleImageLoad}
                        onError={handleImageError}
                        style={{ display: loading ? 'none' : 'block' }}
                    />
                    <button onClick={onClose} className="absolute top-2 right-2 text-white hover:text-gray-300 z-20">
                        <AiOutlineClose size={24} />
                    </button>
                </div>
                <div className="relative bg-event-card-back w-full rounded-b-lg text-center p-4"> 
                    <div className="absolute top-[-4.5rem] left-1/2 transform -translate-x-1/2 z-20">
                        <img 
                            src={pnm.profilePicture} 
                            alt={`${pnm.firstName} ${pnm.lastName}`} 
                            className="w-36 h-36 object-cover rounded-full shadow-lg drop-shadow-lg"
                        />
                    </div>
                    <h2 className="mt-16 text-xl font-bold">{pnm.firstName} {pnm.lastName}</h2>
                    <p className="text-gray-700">{pnm.pnmInfo.gradeLevel} | {pnm.pnmInfo.major}</p>
                    {(pnm.pnmInfo.homeCountry.toString().toLowerCase().trim() === 'united states' ||
                    pnm.pnmInfo.homeCountry.toString().toLowerCase().trim() === 'united states of america' ||
                    pnm.pnmInfo.homeCountry.toString().toLowerCase().trim() === 'us' ||
                    pnm.pnmInfo.homeCountry.toString().toLowerCase().trim() === 'usa' ||
                    pnm.pnmInfo.homeCountry.toString().toLowerCase().trim() === 'america') ? (
                            <p className="text-gray-500">{pnm.pnmInfo.homeTown?.city.toString().trim()}, {pnm.pnmInfo.homeTown?.state.toString().trim()}</p>
                    ) : (
                        <p className="text-gray-500">{pnm.pnmInfo.homeCountry.toString().trim()}</p>
                    )}
                    <p className={`mt-2 text-sm ${pnm.pnmInfo.gpa < 3.0 ? 'text-red-500' : 'text-black'}`}>
                        GPA: {pnm.pnmInfo.gpa.toFixed(2)}
                    </p>
                    <div className="flex justify-center mt-4 space-x-2">
                        {recruitmentEvents.map((event, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <span
                                    className={`w-6 h-6 rounded-full ${checkAttendance(event) ? 'bg-green-500' : 'bg-red-500'}`}
                                ></span>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center mt-4 space-x-3">
                        {pnm.socialMediaHandles.instagram && (
                            <a href={`https://instagram.com/${pnm.socialMediaHandles.instagram}`} target="_blank" rel="noopener noreferrer">
                                <FaInstagram className="w-6 h-6 text-pink-500 hover:text-pink-600" />
                            </a>
                        )}
                        {pnm.socialMediaHandles.facebook && (
                            <a href={`https://facebook.com/${pnm.socialMediaHandles.facebook}`} target="_blank" rel="noopener noreferrer">
                                <FaFacebook className="w-6 h-6 text-blue-600 hover:text-blue-700" />
                            </a>
                        )}
                        {pnm.socialMediaHandles.twitter && (
                            <a href={`https://twitter.com/${pnm.socialMediaHandles.twitter}`} target="_blank" rel="noopener noreferrer">
                                <FaTwitter className="w-6 h-6 text-blue-400 hover:text-blue-500" />
                            </a>
                        )}
                        {pnm.socialMediaHandles.linkedin && (
                            <a href={`https://linkedin.com/in/${pnm.socialMediaHandles.linkedin}`} target="_blank" rel="noopener noreferrer">
                                <FaLinkedin className="w-6 h-6 text-blue-700 hover:text-blue-800" />
                            </a>
                        )}
                    </div>

                    {isOfficer && (
                        <div className="mt-4 flex justify-around w-full">
                            <button
                                onClick={() => handlePriorityChange(1)}
                                className={`py-2 px-6 rounded drop-shadow-md ${priority === 1 ? 'bg-red-500 text-white' : 'bg-event-card-left text-gray-700'} hover:bg-red-600 hover:text-white`}
                            >
                                No
                            </button>
                            <button
                                onClick={() => handlePriorityChange(2)}
                                className={`py-2 px-6 rounded drop-shadow-md ${priority === 2 ? 'bg-yellow-500 text-white' : 'bg-event-card-left text-gray-700'} hover:bg-yellow-600 hover:text-white`}
                            >
                                Maybe
                            </button>
                            <button
                                onClick={() => handlePriorityChange(3)}
                                className={`py-2 px-6 rounded drop-shadow-md ${priority === 3 ? 'bg-green-500 text-white' : 'bg-event-card-left text-gray-700'} hover:bg-green-600 hover:text-white`}
                            >
                                Yes
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <button onClick={onNext} className="text-white">
                <AiOutlineRight size={32} />
            </button>
        </div>
    );
};

export default PnmDetailsModal;