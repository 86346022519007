import React, { useState } from 'react';
import Navbar from '../Navbar';
import CreateEventModal from './CreateEventModal';
import EventCard from './EventCard';
import useAuth from '../../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { selectAllEvents } from '../../../../features/events/eventsApiSlice';
import { IoCreateOutline } from "react-icons/io5";

const ActiveEvents = () => {
    const { chapter, roles, organization } = useAuth();

    const events = useSelector(selectAllEvents);
    const currentDate = new Date();
    const pastEvents = events.filter(event => new Date(event.start) < currentDate);
    const upcomingEvents = events.filter(event => new Date(event.start) >= currentDate);

    const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
    const [editEvent, setEditEvent] = useState(null);
    const [showPast, setShowPast] = useState(false);

    const handleToggleCreateEventModal = () => {
        setIsCreateEventModalOpen(!isCreateEventModalOpen);
        setEditEvent(null);
    };

    const toggleView = () => {
        setShowPast(!showPast);
    }

    const handleEditEvent = (event) => {
        setEditEvent(event);
        setIsCreateEventModalOpen(true);
    };

    return (
        <div className="flex flex-col md:flex-row h-screen">
            <Navbar />
            <div className="flex-grow p-4 flex flex-col overflow-hidden">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-bold">{showPast ? 'Past Events' : 'Events'}</h1>
                    {roles.includes('Officer') && (
                        <div className="flex gap-2">
                            <button
                                onClick={toggleView}
                                className="bg-event-card-button text-white py-2 px-4 rounded hover:bg-event-card-button-hover"
                            >
                                {showPast ? 'View Upcoming Events' : 'View Past Events'}
                            </button>
                            <button
                                onClick={handleToggleCreateEventModal}
                                className="bg-event-card-button text-white py-2 px-2 rounded hover:bg-event-card-button-hover flex center"
                            >
                                <IoCreateOutline size={24} />
                            </button>
                        </div>
                    )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {showPast ? 
                    pastEvents && pastEvents.map(event => (
                        <EventCard 
                            key={event.id} 
                            event={event} 
                            isOfficer={roles.includes('Officer')}
                            onEditEvent={handleEditEvent}
                        />
                    ))
                    :
                    upcomingEvents && upcomingEvents.map(event => (
                        <EventCard 
                            key={event.id} 
                            event={event} 
                            isOfficer={roles.includes('Officer')}
                            onEditEvent={handleEditEvent}
                        />
                    ))}
                </div>
            </div>
            {isCreateEventModalOpen && (
                <CreateEventModal 
                    chapterId={chapter} 
                    organizationId={organization} 
                    onClose={handleToggleCreateEventModal} 
                    event={editEvent}
                />
            )}
        </div>
    );
};

export default ActiveEvents;