import { createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';
import { setMembers } from './usersSlice';

const activeMembersAdapter = createEntityAdapter({});

const initialActiveMembersState = activeMembersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getChapterUsers: builder.query({
            query: ({ chapterId }) => ({
                url: `/chapters/${chapterId}/users`
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setMembers({ members: data }));
                } catch (error) {
                    console.error('Failed to set members:', error);
                }
            },
            providesTags: (result, error, { chapterId }) => [{ type: 'User', id: chapterId }]
        }),
        addNewUser: builder.mutation({
            query: (formData) => ({
                url: '/users',
                method: 'POST',
                body: formData
            }),
            invalidatesTags: [
                { type: 'User', id: 'LIST' }
            ]
        }),
        updateUser: builder.mutation({
            query: (formData) => ({
                url: '/users',
                method: 'PATCH',
                body: formData,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        verifyUser: builder.mutation({
            query: ({ userId, approve }) => ({
                url: `/users/${userId}/verify`,
                method: 'PATCH',
                body: { approve },
            }),
            invalidatesTags: (result, error, { userId }) => [{ type: 'User', id: userId }]
        }),    
        followChapter: builder.mutation({
            query: ({ userId, chapterId, follow }) => ({
                url: `/users/${userId}/chapters/${chapterId}/follow`,
                method: 'PATCH',
                body: { follow }
            }),
            invalidatesTags: (result, error, { userId }) => [
                { type: 'User', id: userId }
            ]
        }),
        updatePriorityStatus: builder.mutation({
            query: ({ userId, chapterId, priority }) => ({
                url: `/users/${userId}/chapters/${chapterId}/priority`,
                method: 'PATCH',
                body: { priority },
            }),
            invalidatesTags: (result, error, { userId }) => [{ type: 'User', id: userId }],
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: '/users',
                method: 'DELETE',
                body: {userId}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg }
            ]
        }),        
    }),
});

export const {
    useGetChapterUsersQuery,
    useAddNewUserMutation,
    useUpdateUserMutation,
    useVerifyUserMutation,
    useFollowChapterMutation,
    useUpdatePriorityStatusMutation,
    useDeleteUserMutation
} = usersApiSlice;