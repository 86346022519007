import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import ActiveRecruitment from '../pages/active/recruitment/ActiveRecruitment';

const RecruitmentLayout = () => {
    const { roles } = useAuth();

    if (roles.includes('Active')) {
        return <ActiveRecruitment />
    } else {
        return <Navigate to="/" replace />
    }
};

export default RecruitmentLayout;