import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import logo from '../../../img/logo-full.png';
import SignUpModal from './SignUpModal';
import { useLoginMutation } from '../../../features/auth/authApiSlice';
import { organizationsApiSlice } from '../../../features/organizations/organizationsApiSlice';
import { chaptersApiSlice } from '../../../features/chapters/chaptersApiSlice';
import { store } from '../../../app/store';
import Loading from './Loading';

const Public = () => {
    const userRef = useRef();
    const errRef = useRef();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();

    const [login, { isLoading }] = useLoginMutation();

    useEffect(() => {
        userRef.current?.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [email, password]);

    useEffect(() => {
        const organizations = store.dispatch(organizationsApiSlice.endpoints.getOrganizations.initiate());
        const chapters = store.dispatch(chaptersApiSlice.endpoints.getChapters.initiate());

        return () => {
            organizations.unsubscribe();
            chapters.unsubscribe();
        };
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login({ email, password }).unwrap();
            localStorage.setItem('persist', true);
            setEmail('');
            setPassword('');
            navigate('/');
        } catch (err) {
            if (!err.status) {
                setErrMsg('No server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Email or Password');
            } else if (err.status === 401) {
                setErrMsg('Incorrect Password');
            } else {
                setErrMsg(err.data?.message || 'Login failed');
            }
            errRef.current?.focus();
        }
    };

    const handleEmailInput = (e) => setEmail(e.target.value);
    const handlePwdInput = (e) => setPassword(e.target.value);
    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const toggleModal = () => setIsOpen(!isOpen);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="flex flex-col h-screen w-full overflow-y-hidden">
            <div className="flex flex-grow justify-center items-center">
                <div className="flex flex-col lg:flex-row justify-center gap-8 lg:gap-[256px]">
                    <div className="flex flex-col justify-center items-center gap-4">
                        <img
                            src={logo}
                            alt="Logo"
                            className="block w-72 lg:w-96"
                        />
                        <h1 className="hidden lg:block">Simplifying Greek Life.</h1>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <form onSubmit={handleSubmit} className="flex flex-col justify-center gap-4 bg-gray-200 border rounded-lg px-4 py-6">
                            {errMsg && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                                    <strong className="font-bold">Error: </strong>
                                    <span className="block sm:inline">{errMsg}</span>
                                </div>
                            )}
                            <input
                                type="email"
                                id="email"
                                ref={userRef}
                                value={email}
                                onChange={handleEmailInput}
                                autoComplete="off"
                                placeholder="Email"
                                className="border border-gray-300 text-gray-900 focus:placeholder-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-80 p-3"
                            />
                            <div className="relative w-80">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    value={password}
                                    onChange={handlePwdInput}
                                    placeholder="Password"
                                    className="border border-gray-300 text-gray-900 focus:placeholder-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3 pr-10" // Add padding-right for icon
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-700 hover:text-gray-900 focus:outline-none"
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>
                            <button type="submit" className="bg-pacific-blue hover:bg-turquoise-blue text-white font-bold py-2 px-4 rounded">
                                Log In
                            </button>
                            <hr className="h-px bg-gray-300 border-0" />
                            <button type="button" onClick={toggleModal} className="bg-pacific-blue hover:bg-turquoise-blue text-white font-bold py-2 px-4 rounded">
                                Sign Up
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex justify-center items-center border-t-2 h-8">
                <p>One Greek LLC &copy; 2024</p>
            </footer>
            <SignUpModal isOpen={isOpen} onClose={toggleModal} />
        </div>
    );
};

export default Public;