import React, { useState } from 'react';
import { AiOutlineSearch, AiOutlineSetting } from 'react-icons/ai';
import { FiLogOut } from "react-icons/fi";
import { useSendLogoutMutation } from '../../../features/auth/authApiSlice';
import ChapterSearchModal from './ChapterSearchModal';
import useAuth from '../../../hooks/useAuth';
import PnmEventCard from './PnmEventCard';
import Loading from '../public/Loading';
import Logo from '../../../img/logo-alt-white.png';
import { useNavigate } from 'react-router-dom';
import SettingsModal from './SettingsModal';
import { useSelector } from 'react-redux';
import { selectAllEvents } from '../../../features/events/eventsApiSlice';

const Pnm = () => {
    const user = useSelector(state => state.auth.user);
    const events = useSelector(selectAllEvents);
    const { id, organization, pnmInfo } = useAuth();
    const [sendLogout, { isLoading }] = useSendLogoutMutation();
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

    const navigate = useNavigate();

    const handleOpenSearchModal = () => {
        setIsSearchModalOpen(true);
    };

    const handleCloseSearchModal = () => {
        setIsSearchModalOpen(false);
    };

    const handleOpenSettingsModal = () => {
        setIsSettingsModalOpen(true);
    };

    const handleCloseSettingsModal = () => {
        setIsSettingsModalOpen(false);
    };

    if (!organization || !pnmInfo || isLoading) {
        return <Loading />;
    }

    return (
        <div className="min-h-screen flex flex-col">
            <header className="bg-pacific-blue text-white p-4 shadow-md flex justify-between items-center sticky top-0 z-50">
                <img
                    src={Logo}
                    alt="Logo Alt White"
                    className="w-40 cursor-pointer"
                    onClick={() => navigate('/')}
                />
                <div className="flex items-center space-x-4">
                    <AiOutlineSearch
                        size={28}
                        onClick={handleOpenSearchModal}
                        className="cursor-pointer hover:text-gray-300"
                    />
                    <AiOutlineSetting
                        size={28}
                        onClick={handleOpenSettingsModal}
                        className="cursor-pointer hover:text-gray-300"
                    />
                    <FiLogOut
                        size={28}
                        onClick={sendLogout}
                        className="cursor-pointer hover:text-gray-300"
                    />
                </div>
            </header>
            <main className="flex-grow overflow-y-auto p-6">
                {pnmInfo.chaptersFollowing.length === 0 ? (
                    <div className="flex flex-col items-center justify-center text-center min-h-[50vh]">
                        <p className="text-lg font-bold">You are not following any chapters yet.</p>
                        <p className="text-md mt-2">Click the search button above to follow chapters!</p>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                        {events.map(event => (
                            <PnmEventCard key={event.id} event={event} />
                        ))}
                    </div>
                )}
            </main>
            <ChapterSearchModal
                organizationId={organization}
                userId={id}
                followedChapters={pnmInfo.chaptersFollowing.map(follow => follow.chapter.toString())}
                isOpen={isSearchModalOpen}
                onClose={handleCloseSearchModal}
            />
            {isSettingsModalOpen && (
                <SettingsModal
                    isOpen={isSettingsModalOpen}
                    onClose={handleCloseSettingsModal}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    profilePicture={user.profilePicture}
                    id={id}
                />
            )}
        </div>
    );
};

export default Pnm;