import { useSendLogoutMutation } from "../../../features/auth/authApiSlice";

const UnverifiedActive = () => {
    const [sendLogout] = useSendLogoutMutation();

    const handleLogout = async () => {
        try {
            await sendLogout().unwrap();
        } catch (err) {
            console.error('Failed to log out:', err);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md text-center">
                <h1 className="text-2xl font-bold mb-4 text-red-600">Pending Verification</h1>
                <p className="mb-6 text-gray-700">
                    Your account is currently under review. You will be notified once your account is verified.
                </p>
                <button
                    onClick={handleLogout}
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                    Log Out
                </button>
            </div>
        </div>
    );
}

export default UnverifiedActive;