import React, { useState, useEffect } from 'react';
import { useAddNewEventMutation, useUpdateEventMutation } from '../../../../features/events/eventsApiSlice';
import { AiOutlineClose } from 'react-icons/ai';
import useAuth from '../../../../hooks/useAuth';

const CreateEventModal = ({ chapterId, organizationId, onClose, event }) => {
    const { id: userId } = useAuth();
    const [formData, setFormData] = useState({
        name: '',
        locationName: '',
        locationAddress: '',
        start: '',
        end: '',
        type: 'Recruitment',
        visibility: 'public'
    });

    const [addNewEvent, { isLoading: isCreating }] = useAddNewEventMutation();
    const [updateEvent, { isLoading: isUpdating }] = useUpdateEventMutation();

    useEffect(() => {
        if (event) {
            setFormData({
                name: event.name,
                locationName: event.location.name,
                locationAddress: event.location.address,
                start: event.start,
                end: event.end,
                type: event.type,
                visibility: event.visibility,
            });
        }
    }, [event]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const convertToArizonaTime = (datetime) => {
        const date = new Date(datetime);
        const options = {
            timeZone: 'America/Phoenix',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        const formatter = new Intl.DateTimeFormat([], options);
        const parts = formatter.formatToParts(date);
        
        const formattedDate = `${parts.find(part => part.type === 'year').value}-${parts.find(part => part.type === 'month').value}-${parts.find(part => part.type === 'day').value}T${parts.find(part => part.type === 'hour').value}:${parts.find(part => part.type === 'minute').value}:${parts.find(part => part.type === 'second').value}`;
        
        return new Date(formattedDate);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const eventData = {
            name: formData.name,
            chapter: chapterId,
            organization: organizationId,
            location: {
                name: formData.locationName,
                address: formData.locationAddress
            },
            start: convertToArizonaTime(formData.start),
            end: convertToArizonaTime(formData.end),
            type: formData.type,
            visibility: formData.visibility,
            author: userId,
        };

        try {
            if (event) {
                await updateEvent({ id: event._id, ...eventData }).unwrap();
            } else {
                await addNewEvent(eventData).unwrap();
            }
            onClose();
        } catch (err) {
            console.error(`Failed to ${event ? 'update' : 'create'} event:`, err);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
                <button onClick={onClose} className="absolute top-3 right-3 text-gray-500 hover:text-gray-700">
                    <AiOutlineClose size={24} />
                </button>
                <h2 className="text-xl font-bold mb-4">{event ? 'Edit Event' : 'Create Event'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Event Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Location Name</label>
                        <input
                            type="text"
                            name="locationName"
                            value={formData.locationName}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Location Address</label>
                        <input
                            type="text"
                            name="locationAddress"
                            value={formData.locationAddress}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Start Date and Time</label>
                        <input
                            type="datetime-local"
                            name="start"
                            value={formData.start}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">End Date and Time</label>
                        <input
                            type="datetime-local"
                            name="end"
                            value={formData.end}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Event Type</label>
                        <select
                            name="type"
                            value={formData.type}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        >
                            <option value="Recruitment">Recruitment</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Event Visibility</label>
                        <select
                            name="visibility"
                            value={formData.visibility}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                        >
                            <option value="public">Public</option>
                            <option value="private">Private</option>
                        </select>
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                            disabled={isCreating || isUpdating}
                        >
                            {event ? 'Update Event' : 'Create Event'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateEventModal;