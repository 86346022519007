import React, { useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { FaPencilAlt } from 'react-icons/fa';
import { useUpdateUserMutation } from '../../../features/users/usersApiSlice';

const SettingsModal = ({ isOpen, id, onClose, firstName, lastName, profilePicture }) => {
    const [newProfilePicture, setNewProfilePicture] = useState(profilePicture);
    const [selectedFile, setSelectedFile] = useState(null);
    const [updateUser] = useUpdateUserMutation();

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setNewProfilePicture(URL.createObjectURL(file));
        }
    };

    const handleSaveChanges = async () => {
        if (selectedFile) {
            try {
                const formData = new FormData();
                formData.append('id', id);
                formData.append('profilePicture', selectedFile);

                await updateUser(formData).unwrap();
                onClose();
            } catch (err) {
                console.error('Failed to update profile picture:', err);
            }
        } else {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">Settings</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <IoIosClose size={32} />
                    </button>
                </div>
                <div className="relative flex flex-col items-center gap-4">
                    <div className="relative">
                        <img
                            src={newProfilePicture}
                            alt="Profile"
                            className="w-32 h-32 object-cover rounded-full cursor-pointer"
                            onClick={() => document.getElementById('imageInput').click()}
                        />
                        <div
                            className="absolute bottom-1 right-1 bg-gray-400 text-white rounded-full p-1 cursor-pointer"
                            onClick={() => document.getElementById('imageInput').click()}
                        >
                            <FaPencilAlt size={18} />
                        </div>
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        id="imageInput"
                        className="hidden"
                        onChange={handleImageChange}
                    />
                    <p className="text-lg font-bold">{`${firstName} ${lastName}`}</p>
                    <button
                        onClick={handleSaveChanges}
                        className="bg-pacific-blue hover:bg-turquoise-blue text-white font-bold py-2 px-4 rounded mt-4"
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SettingsModal;